
.main_header_container{
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0% 3%;
  height: 100px;
}


.signup-link{
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;
  color: #999;
}

.signup-link:hover {
  text-decoration: none;
  color: #49FB35;
  cursor: pointer;
}

.signup-link:link{
  text-decoration: none;
  color: #999;
}

.links-auth{
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
}

.logo-title{
  font-size: 20px;
  color: #49FB35;
  margin-right: 10%;
  text-align: center;
  text-decoration: none;
}

#left-nav-container {
  height: 80px;
  width: 80px;
  margin: 35px;
  border: 3px solid #49FB35;
  background-image: linear-gradient(#49FB35, #49FB35);
  background-repeat: no-repeat;
  transition: background-size .5s, color .5s;
  background-size: 0 0;
  background-position: 50% 50%;
  border-radius: 40px;
  display: flex;
  justify-content: right;
  align-items: center;
}

#left-nav-container:hover {
  background-size: 100% 100%;
  color: #49FB35;
  cursor: pointer;
}

#left-nav-container:hover .logo-title {
  color: black;
}