#follow-item-container {
  background-color: black;
  transition: box-shadow .2s;
  display: grid;
  grid-template-columns: 3% 14% 60% 3% 16% 3%;
  height: 75px;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 10px 0px;
  z-index: 2;
  font-family: Hermes-Regular;
}

#follow-item-container:hover {
  cursor: pointer;
  box-shadow: 0 0 30px #49FB35;
}

#fii-profile-image-container{
  grid-column-start: 2;  
  color: white;
}

#follow-button-container-profile{
  grid-column-start: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#follower-detail-container {
  grid-column-start: 3;
  /* margin-top: 17px; */
  margin-left: 20px;
  border-radius: 10px;
  /* text-align: center; */
  border: #49FB35 solid 1px;
  display: flex;
  align-items: center;
  justify-self: center;
}

#follow-button-container-profile, #fii-profile-image-container, #follower-detail-container {
  height: 75px;
  width: 100%;
}

#fii-profile-image-container {
  background-color: aquamarine;
  border-radius: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}


#mini-thumbnail-follow-item {
  height: 100%;
  width: 100%;
}


#mini-thumbnail-follow-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.follow-button-on-item {
  border: 2px solid #49FB35;
}

.positioning-follow-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-follow-info1 {
  color: #49FB35;
  font-size: 30px;
  margin-left: 10px;
}

.user-follow-info2{
  color: #49FB35;
  font-size: 30px;
  margin-left: 20px;
}