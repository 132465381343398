.background-div-profile {
  background-image: url('../SessionForms/darkpurple.jpeg');
  height: 100%;
  padding-bottom: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#profile-main-container{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

#current-user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 5px;
  color: #49FB35;
  font-family: Hermes-Regular;
  
}

#current-username {
  font-size: 60px;
  padding-left: 10px;
}

#current-email {
  padding-left: 10px;
}

#to-level-user-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#personal-info-container {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 300px;
  border: 3px solid #49FB35;
  border-radius: 15px;
}

.move-name{
  font-size: 20px;
  font-family: Hermes-Regular;
  padding: 5px;
  width: 75%;
  border-right: #49FB35 solid 2px;
  color: #49FB35
}

.move-set-container{
  margin-top: 40px;
  width: 40%;
  border: solid #49FB35 2px;
  border-bottom: none;

}

.individual-set-container{
  display: flex;
  justify-content: space-between;
  border-bottom: solid #49FB35 2px;
  cursor: pointer;
}

.individual-set-container1{
  display: flex;
  justify-content: space-between;
  border-bottom: solid #49FB35 2px
}

.move-set-title-name{
  margin-left: 5px;
  font-size: 25px;
  font-weight: 500;
  color: #49FB35
}

.move-set-title-binding {
  font-size: 25px;
  font-weight: 500;
  width: 40%;
  text-align: center;
  color: #49FB35
}

.move-binding{
  width: 40%;
  text-align: center;
  color: #49FB35;
  font-family: Hermes-Regular;
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  /* position: absolute; */
  width: fit-content;
  padding-left: 0;
  left: 0;
  display: none;
  background: white;
  padding: 20px 28px;
  box-sizing: border-box;
  border: none;
  background: #49FB35;
  color: #2E294E;
}

ul li:hover>ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 250px;
  text-align: left;
  padding-top: 20px;
  
}

ul li ul li:hover{
  background-color: #2E294E;
  color: #49FB35;
  animation: blinkingBackground 3.5s infinite;
}

@keyframes blinkingBackground {
  0% {
    background-color: #2E294E;
    color: #49FB35;
  }

  25% {
    background-color: #49FB35;
    color:#2E294E
  }

  50% {
    background-color: #2E294E;
    color: #49FB35;
  }

  75% {
    background-color: #49FB35;
    color: #2E294E
  }

  100% {
    background-color: #2E294E;
    color: #49FB35; 
  }
}

.create-button{

      margin-top: 25px;
      width: 20%;
      border-radius: 3px;
      font-family: 'Common Pixel', sans-serif;
      padding: 10px 10px;
      display: inline-block;
      color: black;
      letter-spacing: 2px;
      background-color: rgb(180, 180, 180);
      text-transform: uppercase;
      text-decoration: none;
      overflow: hidden;
      animation: glowing 2000ms infinite;

  
  
    }
    @keyframes glowing {
      0% {
        background-color: #2ba805;
        box-shadow: 0 0 3px #2ba805;
      }
  
      50% {
        background-color: #49FB35;
        box-shadow: 0 0 10px #49FB35;
      }
  
      100% {
        background-color: #2ba805;
        box-shadow: 0 0 3px #2ba805;
      }
    }
  ul li{
    width: fit-content;
  }
    
.add-keybind-button{
  display: inline-block;
  padding: 20px 28px;
  border-radius: 50px;
  box-sizing: border-box;
  border: none;
  background: #49FB35;
  color: #2E294E;
  font-size: 24px;
  cursor: pointer;
  width: fit-content;
  font-family: Hermes-Regular;
}

#dropdown-container{
  padding-top: 5%;
  padding-bottom: 5%;
  margin-left: 10%;
  font-family: Hermes-Regular;

}

.binding-container-profile, .liked-binding-container-profile{
  margin-left: 17%;
}

.follows-container-profile {
  margin-left: 17%;
}

.profile-game-title{
  font-size: 40px;
  color: #49FB35;
  margin-bottom: 40px;

}

.x-positioning img{
  height: 50px;
  width: 50px;
  cursor: pointer;
}

#display-switcher-headers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.switcher-header {
  margin: 1% 5%;
  padding: 15px;
  background-color: #49FB35;
  color: black;
  font-family: Hermes-Regular;
  font-size: 16px;
  border: 2px solid #49FB35;
}

.switcher-header:hover {
  cursor: pointer;
  background-color: black;
  color: #49FB35;
}

#personal-info-img-and-info-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  width: 96%;
  padding: 0% 2%;
}

#working {
  grid-column-start: 1;
}

