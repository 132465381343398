#follow-text {
    background-color: #49FB35;
    color: black;
}

#unfollow-text {
    background-color: black;
    color: #49FB35;
}

#follow-text, #unfollow-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 20px;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    height: 100%;
}

#follow-button-container {
    border: 2px solid #49FB35;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;


}