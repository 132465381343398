.signup-text-container{
  margin-right: 41px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-text{
  margin-right: 41px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.signup-text-container>label {
  width: 100%;
  padding: 4px 0;
  text-align: right;
}

.signup-text-container>label>span {
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
  color: #49FB35;
}

.signup-text-container>input {
  width: 200px;
}

/* .signup-text-container>input[type="submit"] {
  width: 100%;
} */

.session-form>h2{
  font-size: 30px;
  margin-bottom: 10px;
  color:  #49FB35;
}

.login-form2>h2 {
  font-size: 25px;
  margin-bottom: 10px;
  color: #49FB35;
}

.session-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  /* height: 320px; */
  /* left: 25%;
  width: 50%;
  border: #49FB35 dotted 5px;
    box-shadow: 0 0 50px #39ff14;
  position: absolute;
  top: calc(var(--nav-height) + 19%); */
}

.login-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* height: 265px; */
  /* width: fit-content; */
  /* left: 25%;
  width: 50%; */
  border: #49FB35 dotted 5px;
  /* box-shadow: 0 0 50px #39ff14; */
  /* position: absolute; */
  /* top: calc(var(--nav-height) + 19%); */
}

.login-form2{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: #49FB35 dotted 5px;
  /* box-shadow: 0 0 50px #39ff14; */
  top: calc(var(--nav-height) + 19%);
}

.submit-button{
  margin-top: 10px;
  /* width: 20%; */
  border-radius: 3px;
  font-family: 'Common Pixel', sans-serif;
  padding: 10px 10px;
  display: inline-block;
  color: black;
  letter-spacing: 2px;
  background-color: rgb(180, 180, 180);
  text-transform: uppercase;
  text-decoration: none;
  overflow: hidden;
  animation: glowing 2000ms infinite;
}


@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }

  50% {
    background-color: #49FB35;
    box-shadow: 0 0 10px #49FB35;
  }

  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

.submit-button:hover {
  color: #2E294E;
  background: #49FB35;
  box-shadow: 0 0 50px #49FB35;
  animation: none;
}

.background-div{
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./darkpurple.jpeg');
}

.login-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}