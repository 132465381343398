#show-main-container {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 30% 70%;
    background: radial-gradient(rgb(146, 55, 231), rgb(26, 1, 49));
    font-family: Hermes-Regular;
    color: #49FB35;
}

#user-show-bindings-banner {
    font-size: 50px;
}

#follow-button-user-show {
    height: 50px;
    padding: 5px;
    margin-bottom: 5px;
}

#show-info-box {
    height: 500px;
    width: 20%;
}

#user-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
    color: black;
    width: 70%;
    margin-top: 20%;
    margin-left: 10%;
    height: fit-content;
    border: 3px solid #49FB35;
    border-radius: 15px;
    position: sticky;
    top: 10%;
    z-index: 100;
    scroll-padding-top: 100px;
}

#show-username {
    font-family: Hermes-Regular;
    color: #49FB35;
    font-size: 30px;
    padding-left: 10px;
}

#follower-container {
    font-family: Hermes-Regular;
    color: #49FB35;
    font-size: 20px;
    padding-left: 10px;
}

#show-bindings-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-left: 40%; */
    width: 100%;
    /* height: 100vh; */
    margin-top: 20%;
    margin-bottom: 10%;
    
}


#show-bindings-banner {
    display: flex;
    align-self: center;
    margin-right: 20%;
    font-size: 20px;
  
}

#submit-icon {
    display: flex;
   justify-content: space-between;
    text-align: center;
    margin-right: 10;
}
#file-input {
    opacity: 0%;
    height: 100%;
    cursor: pointer;
    font-size: 100%;
}
#submit-container {
    display: flex;
    flex-wrap: wrap;
    width: 70vw;
    margin-right: 90px;

}
#submit-form {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    display: flex;
    border: 1px dotted black;
    height: fit-content;
    background-color: white;    
    cursor: pointer;
}

#photo-form {
    margin-top: 200px;
    cursor: pointer;

}

#user-actual-prof-pic {
    max-width: 100%;
    height: 0px;
    padding-top: 100%;
    background-color: white;
    position: relative;
    border-radius: 10px;
}

#user-actual-prof-pic > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0%;
    top: 0%;
    border-radius: 10px;
}

#prof-pic-user-show {
    position: absolute;
    top: 30%;
    left: 5%;
    font-size: 30px;
}