
#binding-item-container {
    background-color: black;
    transition: box-shadow .2s;
    display: grid;
    grid-template-columns: 3% 20% 50% 17% 10%;
    height: 150px;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 10px 0px;
    z-index: 2;
    font-family: Hermes-Regular;
}

#binding-item-container:hover {
    cursor: pointer;
    box-shadow: 0 0 30px #49FB35; 
}

#game-mini-thumbnail-container {
    grid-column-start: 2;
}

#actual-mini-thumbnail {
    color: aquamarine;
    border-radius: 5px;
    border: 2px solid aquamarine;
    margin-top: 17px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

#binding-detail-container {
    grid-column-start: 3;
    margin-top: 17px;
    margin-left: 20px;
    border-radius: 10px;
    /* text-align: center; */
    height: 117px;
    flex-wrap: nowrap;
    overflow: scroll;
    border: #49FB35 solid 1px;
}

#controller-mini-thumbnail {
    grid-column-start: 4;
    display: flex;
    justify-content: right;
    align-items: center;
    width: auto;
}


#controller-icon-container {
    background-color: #49FB35;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

#controller-icon {
    color: #49FB35;
    height: inherit;
    width: inherit;
    object-fit: contain;
}

.x-positioning {
    width: 100%;
    margin-right: 30%;
    display: flex;
    justify-content: right;
}

.binding-set-container {
    display: flex;
    justify-content: space-between;
    border-bottom: solid #49FB35 2px;
    /* width: fit-content; */
}

.bindpage-move-title-name {
    font-size: 20px;
    font-weight: 500;
    color: #49FB35;
    font-family: Hermes-Regular;
}

.bindpage-move-title-binding {
    font-size: 20px;
    font-weight: 500;
    width: 30%;
    text-align: center;
    color: #49FB35;
    font-family: Hermes-Regular;
}

.toggle-menu{
    /* position: absolute;
    height: 150px;
    margin-top: 3px;
    padding: 5px 5px;
    margin-left: 66.3%;
    height: 150px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px; */
    grid-column-start: 5;
    /* background-color: #49FB35; */
    margin-left: 8px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.toggle-menu div{
    color: white
}


#update-binding2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

#author-div {
    padding: 5px 0px;
    font-weight: 900;
    font-size: 20px;
    font-family: Hermes-Regular;
    padding-left: 10px;

}

#a-spacer {
    border-bottom: 1px solid;
    width: 75%;
    margin-left: 5%;
}

#edit-option-button-1:hover {
    color: #49FB35
}

#edit-option-button-2:hover {
    color: #49FB35
}

#like-button-container {
    /* size: 30px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#like-total-b-item {
    font-size: 24px;
    padding-right: 5px;
    font-family: 'Common Pixel', sans-serif;
}

#update-delete-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

#binding-index-item-follow {
    width: 70%;
    padding-top: 10px;
}

#liked > .material-symbols-outlined {
    font-variation-settings:
    'FILL' 1,
    'wght' 700,
    'GRAD' 0,
    'opsz' 48
}

#not-liked > .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 700,
    'GRAD' 0,
    'opsz' 48
}
